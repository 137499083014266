import { useEffect, useState } from "react";
import { FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import useAxios from "../hooks/useAxios";

const RenameFileModal = ({ show, file, onSave, setShow }) => {

    const [data, setData] = useState({ name: '' });

    const [{ loading }, renameFile] = useAxios({ url: `/files/${file?.id}/rename`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        if (show) setData({ name: file?.name });
    }, [show])

    const handleChange = (e) => {
        setData(oldData => ({ ...oldData, [e.target.name]: e.target.value }));
    }

    const handleCreate = (e) => {
        e.preventDefault?.();

        renameFile?.({ data }).then(res => {
            onSave?.(res.data?.data);
        });
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Cambiar nombre</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleCreate}>
                    <FloatingLabel
                        controlId="folder_name"
                        label="Escriba el nombre"
                        className="mb-3"
                    >
                        <Form.Control
                            type="text"
                            placeholder="Nombre"
                            name="name"
                            value={data?.name}
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                    <div className="text-end">
                        <button type="button" className="btn text-danger me-1 fw-bold" onClick={() => setShow(false)}>
                            Cancelar
                        </button>
                        <button disabled={loading} className="btn text-primary me-1 fw-bold">
                            {
                                loading ?
                                    <Spinner animation="grow" />
                                    :
                                    'Cambiar nombre'
                            }
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default RenameFileModal;