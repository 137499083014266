import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../hooks/useAxios";
import { useFeedBack } from "../../../context/FeedBackContext";
import { Spinner } from "react-bootstrap";

const CreateDocumentType = ({ endPoint }) => {

    const navigate = useNavigate();

    const { setCustomAlert } = useFeedBack();

    const [data, setData] = useState({
        name: '',
        prefix: '',
        days: ''
    });

    const [{ data: storeData, loading }, storeRecord] = useAxios({ url: `${endPoint}`, method: 'POST' }, { manual: true, useCache: false });

    const handleChange = (e) => {
        setData((oldData) => {
            return {
                ...oldData,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault?.();
        storeRecord({ data }).then((response) => {
            setCustomAlert({ show: true, message: "El registro se ha creado exitosamente.", severity: "success", title: 'Enhorabuena' });
            navigate('/tipos-de-documentos');
        })
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="d-flex align-items-center w-100">
                    <h4 className="card-title">
                        Crear tipo de documento
                    </h4>
                    <button type="button" className="btn btn-primary btn-xs ms-auto" onClick={() => navigate(-1)}>
                        Volver
                    </button>
                </div>

            </div>
            <form onSubmit={handleSubmit}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group mb-3">
                                <label for="name" className="text-primary">Nombre</label>
                                <input
                                    type="text"
                                    name="name"
                                    value={data?.name}
                                    className="form-control"
                                    id="name"
                                    placeholder="nombre..."
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-3">
                                <label for="days" className="text-primary">
                                    Nro. de Dias de expiración
                                </label>
                                <input
                                    type="number"
                                    name="days"
                                    value={data?.days}
                                    className="form-control"
                                    id="days"
                                    placeholder="Nro. de dias..."
                                    onChange={handleChange}
                                />
                                <small>
                                    Ingrese el número de días en los cuales expirarán los documentos de este tipo. <b>Dejelo vacio si estos documentos no expiran nunca.</b>
                                </small>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-3">
                                <label for="prefix" className="text-primary">Prefijo</label>
                                <input
                                    type="text"
                                    name="prefix"
                                    value={data?.prefix}
                                    className="form-control"
                                    id="prefix"
                                    placeholder="prefijo..."
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer text-end">
                    <button className="btn btn-danger" type="button" onClick={() => navigate(-1)}>
                        Cancelar
                    </button>
                    <button disabled={loading} className="btn btn-primary ms-2">
                        {
                            loading ?
                                <Spinner variant="light" style={{ width: 18, height: 18 }} animation="grow" />
                                :
                                'Enviar'
                        }
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CreateDocumentType;