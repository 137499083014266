import FileGrid from "./FileGrid";

const FileGridComponent = ({ files, onDelete, onUpdate }) => {
    return (
        <div className="row">
            {
                files?.map((file, i) => {
                    return (
                        <FileGrid
                            onDelete={onDelete}
                            file={file}
                            key={i}
                            onUpdate={onUpdate}
                        />
                    )
                })
            }
        </div>
    )
}

export default FileGridComponent;