import { Modal, Spinner } from "react-bootstrap";
import useAxios from "../hooks/useAxios";

const DeleteFileModal = ({ show, file, onDelete, setShow }) => {

    const [{ loading }, deleteFile] = useAxios({ url: `/files/${file?.id}`, method: 'DELETE' }, { manual: true, useCache: false });

    const handleDelete = (e) => {
        e.preventDefault?.();

        deleteFile?.().then(res => {
            onDelete?.();
            setShow(false);
        });
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Archivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    ¿Estas seguro que quieres eliminar el archivo?
                </p>
                <p className="text-primary">
                    <b>
                        {file?.name}
                    </b>
                </p>
                <div className="text-end">
                    <button type="button" className="btn text-dark me-1 fw-bold" onClick={() => setShow(false)}>
                        Cancelar
                    </button>
                    <button disabled={loading} className="btn text-danger me-1 fw-bold" onClick={handleDelete}>
                        {
                            loading ?
                                <Spinner animation="grow" />
                                :
                                'Si, quiero eliminarlo'
                        }
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteFileModal;