import folder from "../assets/images/file_types/folder.png";
import blank from "../assets/images/file_types/blank.png";
import word from "../assets/images/file_types/word.png";
import excel from "../assets/images/file_types/excel.png";
import powerpoint from "../assets/images/file_types/powerpoint.png";
import pdf from "../assets/images/file_types/pdf.png";
import { FaUserPlus, FaEdit, FaEye, FaFileUpload } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";
import { getAuth } from "../helpers/auth";
import SystemInfo from "./SystemInfo";

const authInfo = JSON.parse(`${getAuth()}`);

export const cutString = (string, length, start, decorator) => {
    return string?.length > length ? `${string?.slice(start || 0, length)}${decorator}` : string;
}


export const dateFine = (date) => {
    const newDateSplited = date?.split('T');

    const day = newDateSplited?.[0];

    const hour = newDateSplited?.[1].split('.')?.[0];

    return `${day} ${hour}`;
}


export const generateArray = (numberOfItems = 1, startAt = 0) => {
    const arrayOfNumbers = [...Array(numberOfItems + startAt).keys()];

    return arrayOfNumbers.slice(startAt);
}

export const addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
}

export const fileTypeToImage = async (extension, fileId) => {
    if (extension === 'dir') return folder;
    if (extension === '.docx' || extension === '.doc') return word;
    if (extension === '.xlsx' || extension === '.xls' || extension === '.csv') return excel;
    if (extension === '.ppt') return powerpoint;
    if (extension === '.pdf') return pdf;
    if (extension === '.png' || extension === '.jpg' || extension === '.jpeg' || extension === '.webp' || extension === '.png') {
        if (fileId) {
            var urlToReturn = blank;
            try {
                const response = await axios.get(`${SystemInfo?.api}/files/${fileId}/download`, { responseType: 'blob', headers: { Authorization: `Bearer ${authInfo?.token}` } });
                urlToReturn = URL.createObjectURL(response?.data);
            } catch (error) {
            }
            return urlToReturn;
        }
    }
    return blank;
}

export const filePermissionTypeToName = {
    read: {
        name: 'leer',
        Icon: <FaEye />
    },
    update: {
        name: 'actualizar',
        Icon: <FaEdit />
    },
    create: {
        name: 'crear',
        Icon: <FaFileUpload />
    },
    delete: {
        name: 'eliminar',
        Icon: <MdDeleteForever />
    },
    management_permissions: {
        name: 'gestionar permisos',
        Icon: <FaUserPlus />
    }
};


export const getExtension = (str) => str.slice(str.lastIndexOf("."));