import { Spinner } from "react-bootstrap";
import useAxios from "../../hooks/useAxios";
import { fileTypeToImage } from "../../util/Utilities";
import AsyncSelect from 'react-select/async';
import useCostCenters from "../../hooks/useCostCenters";
import mapValues from "../../util/mapValues";
import handleLoadSelectOptions from "../../util/handleLoadSelectOptions";
import { useState } from "react";
import useDocumentTypes from "../../hooks/useDocumentTypes";

const Reports = () => {

    const [filters, setFilters] = useState({
        costCenterId: '',
        documentTypeId: ''
    })

    const [{ loading: loadingDocuments, data }, getDocuments] = useAxios({ url: `/reports/documents`, params: filters }, { useCache: false });

    const [{ costCenters, loading: costCentersLoading }, getCostCenters] = useCostCenters({ params: { perPage: 50, orderBy: 'name ASC' } }, { useCache: false });

    const [{ documentTypes, loading: documentTypesLoading }, getDocumentTypes] = useDocumentTypes({ params: { perPage: 50, orderBy: 'name ASC' } }, { useCache: false });

    const handleFilters = (e) => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value
            }
        });
    }

    return (
        <div>
            <div className="row">
                <h3 className="text-center">
                    Reporte de documentos
                </h3>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <p className="text-primary mb-0">
                            Centro de costo
                        </p>
                        <AsyncSelect
                            isClearable
                            onFocus={() => { getCostCenters() }}
                            isLoading={costCentersLoading}
                            defaultOptions={mapValues(costCenters)}
                            name="costCenterId"
                            loadOptions={(e) => handleLoadSelectOptions(e, getCostCenters, { orderBy: 'name ASC' })}
                            placeholder='Escriba el nombre para buscar...'
                            onChange={(e) => {
                                handleFilters({ target: { value: e?.value, name: 'costCenterId' } })
                            }}
                        />
                    </div>
                    <div className="col-md-4">
                        <p className="text-primary mb-0">
                            Tipo de documento
                        </p>
                        <AsyncSelect
                            isClearable
                            onFocus={() => { getDocumentTypes() }}
                            isLoading={documentTypesLoading}
                            defaultOptions={mapValues(documentTypes)}
                            name="costCenterId"
                            loadOptions={(e) => handleLoadSelectOptions(e, getDocumentTypes, { orderBy: 'name ASC' })}
                            placeholder='Escriba el nombre para buscar...'
                            onChange={(e) => {
                                handleFilters({ target: { value: e?.value, name: 'documentTypeId' } })
                            }}
                        />
                    </div>
                    <div className="col-md-4">
                        <p className="text-primary mb-0">
                            Cantidad de documentos
                        </p>
                        <h4>
                            {data?.filesCount ? `${data?.filesCount} Documentos` : '--'}
                        </h4>
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <Spinner variant="danger" style={{ width: 15, height: 15 }} animation="grow" />
                                Vencidos
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                loadingDocuments &&
                                <div className="text-center">
                                    <Spinner variant="primary" style={{ width: 50, height: 50 }} animation="grow" />
                                </div>
                            }
                            {
                                data?.expired.length > 0 && !loadingDocuments ?
                                    <div className="table-responsive" style={{ height: 350 }}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Nombre
                                                    </th>
                                                    <th>
                                                        Prefijo
                                                    </th>
                                                    <th className="text-end">
                                                        Fecha de vencimiento
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data?.expired?.map((file, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <img src={fileTypeToImage(file?.extension)} alt="" style={{ width: 20, height: 20 }} />
                                                                    {file?.name}
                                                                </td>
                                                                <td className="text-end">{file?.prefix ?? '--'}</td>
                                                                <td className="text-end">{file?.expire_at ?? '--'}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="text-center" style={{ height: 350 }}>
                                        No se encontrarón los resultados.
                                    </div>
                            }
                        </div>
                        <div className="card-footer">
                            {data &&
                                <h4 className="text-center">
                                    Documentos: {data?.expiredCount}
                                </h4>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <Spinner variant="warning" style={{ width: 15, height: 15 }} animation="grow" />
                                Por Vencer
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                loadingDocuments &&
                                <div className="text-center">
                                    <Spinner variant="primary" style={{ width: 50, height: 50 }} animation="grow" />
                                </div>
                            }
                            {
                                data?.closeToExpiredFiles.length > 0 && !loadingDocuments ?
                                    <div className="table-responsive" style={{ height: 350 }}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Nombre
                                                    </th>
                                                    <th>
                                                        Prefijo
                                                    </th>
                                                    <th className="text-end">
                                                        Fecha de vencimiento
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data?.closeToExpiredFiles?.map((file, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <img src={fileTypeToImage(file?.extension)} alt="" style={{ width: 20, height: 20 }} />
                                                                    {file?.name}
                                                                </td>
                                                                <td className="text-end">{file?.prefix ?? '--'}</td>
                                                                <td className="text-end">{file?.expire_at ?? '--'}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="text-center" style={{ height: 350 }}>
                                        No se encontrarón los resultados.
                                    </div>
                            }
                        </div>
                        <div className="card-footer">
                            {data &&
                                <h4 className="text-center">
                                    Documentos: {data?.closeToExpiredFilesCount}
                                </h4>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                <Spinner variant="success" style={{ width: 15, height: 15 }} animation="grow" />
                                Vigentes
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                loadingDocuments &&
                                <div className="text-center">
                                    <Spinner variant="primary" style={{ width: 50, height: 50 }} animation="grow" />
                                </div>
                            }
                            {
                                data?.activeFiles.length > 0 && !loadingDocuments ?
                                    <div className="table-responsive" style={{ height: 350 }}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Nombre
                                                    </th>
                                                    <th>
                                                        Prefijo
                                                    </th>
                                                    <th className="text-end">
                                                        Fecha de vencimiento
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data?.activeFiles?.map((file, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <img src={fileTypeToImage(file?.extension)} alt="" style={{ width: 20, height: 20 }} />
                                                                    {file?.name}
                                                                </td>
                                                                <td className="text-end">{file?.prefix ?? '--'}</td>
                                                                <td className="text-end">{file?.expire_at ?? '--'}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="text-center" style={{ height: 350 }}>
                                        No se encontrarón los resultados.
                                    </div>
                            }
                        </div>
                        <div className="card-footer">
                            {data &&
                                <h4 className="text-center">
                                    Documentos: {data?.activeFilesCount}
                                </h4>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mb-3">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-title">
                                Documentos cargados por usuario
                            </div>
                        </div>
                        <div className="card-body">
                            {
                                loadingDocuments &&
                                <div className="text-center">
                                    <Spinner variant="primary" style={{ width: 50, height: 50 }} animation="grow" />
                                </div>
                            }
                            {
                                data?.filesByUser?.length > 0 && !loadingDocuments ?
                                    <div className="table-responsive" style={{ height: 350 }}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Nombre
                                                    </th>
                                                    <th className="text-end">
                                                        Cantidad de documentos
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data?.filesByUser?.map((user, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>
                                                                    <img src={user?.image_path} style={{ width: 40, height: 40 }} />
                                                                    <b style={{ color: 'black' }}>
                                                                        {user?.name}
                                                                    </b>
                                                                </td>
                                                                <td className="text-end">
                                                                    <b style={{ color: 'black' }}>
                                                                        {user?.filesCount}
                                                                    </b>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="text-center" style={{ height: 350 }}>
                                        No se encontrarón los resultados.
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports;