import { FaCheckCircle, FaCloudUploadAlt } from "react-icons/fa";
import useAxios from "../hooks/useAxios";
import { Modal, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { getExtension } from "../util/Utilities";


const UploadFileVersionModal = ({ file, show, setShow, onSave }) => {

    const [filesWithErrors, setFileWithErrors] = useState([]);

    const [showSuccess, setShowSuccess] = useState(false);

    const [{ data: storeData, loading: storeLoading }, storeFile] = useAxios({ url: `files/${file?.id}/versions/store`, method: 'POST' }, { manual: true, useCache: false });

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false);
            }, 3000);
        }
    }, [showSuccess])

    const handleFiles = async (e) => {
        setFileWithErrors([]);


        const newFile = e.target.files[0];

        try {
            var data = new FormData();
            data?.append('version', newFile, newFile?.name);

            const fileResponse = await storeFile({ data });

            if (fileResponse) {
                onSave?.(fileResponse?.data);
                setShowSuccess(true);
            }
        } catch (error) {
            setFileWithErrors(oldValue => {
                return [...oldValue, { file_name: newFile?.name, error_message: error?.response?.data?.message }]
            });
        }
    }

    return (
        <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Cargar Nueva versión</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    storeLoading ?
                        <div className="text-center my-5">
                            <Spinner animation="grow" variant="primary" />
                            <br />
                            <h3>
                                Cargando Archivo
                            </h3>
                        </div>
                        :
                        showSuccess ?
                            <div className="text-center animate__animated animate__zoomIn">
                                <FaCheckCircle className="text-primary" style={{ fontSize: '80px' }} />
                                <br />
                                <br />
                                <h3>
                                    ¡La nueva versión del documento <br /> ha sido cargada exitosamente!
                                </h3>
                                <br />

                                <button className="btn btn-primary" onClick={() => setShowSuccess(false)}>
                                    Cargar nueva versión
                                </button>
                                <br />
                                <br />
                                <button className="btn btn-danger" onClick={() => setShow(false)}>
                                    Cerrar formulario
                                </button>
                            </div>
                            :
                            <label htmlFor="file-input" className="d-flex border w-100" style={{ height: 200, cursor: 'pointer' }}>
                                <p className="m-auto text-center">
                                    <FaCloudUploadAlt className="d-block m-auto" style={{ fontSize: 80 }} />
                                    Haz click aqui para subir los archivos.
                                </p>
                                <input type="file" id="file-input" className="d-none" onChange={handleFiles} />
                            </label>
                }
            </Modal.Body>
        </Modal>
    )
}

export default UploadFileVersionModal;