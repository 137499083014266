import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import useAxios from "../hooks/useAxios";
import { Spinner } from "react-bootstrap";



const EditExpiredAtComponent = ({ file, onUpdate, setEditExpiredAt }) => {

    const [currentExpiredAt, setCurrentExpiredAt] = useState('');

    const [{ data, loading }, updateFile] = useAxios({ url: `/files/${file?.id}/expire-at`, method: 'PUT' }, { manual: true, useCache: false });

    useEffect(() => {
        if (file) setCurrentExpiredAt(file?.expire_at_value);
    }, [file])

    const handleSubmit = () => {
        updateFile({
            data: {
                expireAt: currentExpiredAt
            }
        }).then((response) => {
            onUpdate?.(response?.data?.data);
            setEditExpiredAt(false);
        });
    }

    return (
        <div class="input-group">
            <input type="date" class="form-control" value={currentExpiredAt} onChange={(e) => setCurrentExpiredAt(e.target.value)} />
            <button disabled={loading} title="Cancelar" class="btn btn-xs btn-outline-danger p-2" type="button" style={{ fontSize: 14 }} onClick={() => setEditExpiredAt(false)}>
                <MdOutlineCancel />
            </button>
            <button disabled={loading} title="Enviar" class="btn btn-xs btn-outline-primary p-2" type="button" style={{ fontSize: 14 }} onClick={handleSubmit}>
                {
                    loading ?
                        <Spinner variant="ligth" style={{ width: 14, height: 14 }} animation="grow" />
                        :
                        <FaCheck />
                }
            </button>
        </div>
    )
}

export default EditExpiredAtComponent;