import { useState } from "react"
import { Dropdown } from "react-bootstrap";
import { FaHistory, FaPlus, FaUserPlus, FaEllipsisV, FaEye, FaPencilAlt } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import RenameFileModal from "./RenameFileModal";
import DeleteFileModal from "./DeleteFileModal";
import DetailFileModal from "./DetailFileModal";
import ManagementFilePermissionsModal from "./ManagementFilePermissionsModal";
import UploadFileVersionModal from "./UploadFileVersionModal";
import FileVersionsModal from "./FileVersionsModal";


const FileActionsComponent = ({ file, onSave, onDelete }) => {

    const [show, setShow] = useState('');

    const handleSave = (newFile) => {
        onSave?.(newFile);
        setShow('');
    }

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle variant="null" className="p-0 text-primary" id="dropdown-basic">
                    <FaEllipsisV />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        file?.currentUserCanUpdate &&
                        <Dropdown.Item onClick={() => setShow('new_version')}>
                            <FaPlus />
                            Nueva Versión
                        </Dropdown.Item>
                    }
                    {
                        file?.currentUserCanRead && file?.hasVersions ?
                            <Dropdown.Item onClick={() => setShow('versions')}>
                                <FaHistory />
                                Ver Versiones
                            </Dropdown.Item>
                            :
                            null
                    }
                    {
                        file?.currentUserCanUpdate &&
                        <Dropdown.Item onClick={() => setShow('rename')}>
                            <FaPencilAlt />
                            Cambiar Nombre
                        </Dropdown.Item>
                    }
                    {
                        file?.currentUserCanRead &&
                        <Dropdown.Item onClick={() => setShow('detail')}>
                            <FaEye />
                            Ver detalles
                        </Dropdown.Item>
                    }
                    {
                        file?.currentUserCanManagementPermissions &&
                        <Dropdown.Item onClick={() => setShow('management_permissions')}>
                            <FaUserPlus />
                            Gestionar Permisos
                        </Dropdown.Item>
                    }
                    {
                        file?.currentUserCanDelete &&
                        <Dropdown.Item className="text-danger" onClick={() => setShow('delete')}>
                            <MdDeleteForever />
                            Eliminar
                        </Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
            <RenameFileModal
                file={file}
                onSave={handleSave}
                show={show === 'rename'}
                setShow={setShow}
            />
            <DeleteFileModal
                file={file}
                onDelete={onDelete}
                show={show === 'delete'}
                setShow={setShow}
            />
            <DetailFileModal
                file={file}
                show={show === 'detail'}
                onUpdate={onSave}
                setShow={setShow}
            />
            <ManagementFilePermissionsModal
                file={file}
                show={show === 'management_permissions'}
                setShow={setShow}
            />
            <UploadFileVersionModal
                file={file}
                onSave={handleSave}
                show={show === 'new_version'}
                setShow={setShow}
            />
            <FileVersionsModal
                file={file}
                show={show === 'versions'}
                setShow={setShow}
            />
        </div>
    )
}

export default FileActionsComponent