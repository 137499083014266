import { useEffect, useState } from "react"
import useAxios from "../hooks/useAxios"
import AsyncSelect from 'react-select/async';
import useCostCenters from "../hooks/useCostCenters";
import mapValues from "../util/mapValues";
import handleLoadSelectOptions from "../util/handleLoadSelectOptions";
import { Image } from "react-bootstrap";
import profileImg from '../assets/images/profile.png';
import { AiFillCheckCircle } from "react-icons/ai";
import useUsers from "../hooks/useUsers";


const UserPermissionsCrud = ({ data, setData }) => {

    const [show, setShow] = useState(false);

    const [currentUsers, setCurrentUsers] = useState([]);

    const [filters, setFilters] = useState({
        page: 1,
        perPage: 12,
        file_id: '',
        file_permission: '',
        with_permissions: ''
    });

    const [{ data: permissions, loading: loadingPermissions }, getPermission] = useAxios({ url: `/files/${data?.file_id}/permissions`, params: { type: data?.permission } }, { manual: true, useCache: false })

    const [{ users, total, numberOfPages, loading: loadingUsers }, getUsers] = useUsers({ params: { ...filters }, options: { manual: true, useCache: false } });

    const [{ costCenters, loading: costCentersLoading }, getCostCenters] = useCostCenters({ params: { perPage: 50, orderBy: 'name ASC' } }, { useCache: false });

    useEffect(() => {
        getUsers({ params: filters });
    }, [filters])

    useEffect(() => {
        if (users) {
            setCurrentUsers(oldUsers => {
                return [...oldUsers, ...users];
            })
        }
    }, [users])

    useEffect(() => {
        if (permissions) {
            setData(oldData => ({ ...oldData, userIds: permissions?.data?.map(currentPermission => currentPermission?.user?.id) }))
        }
    }, [permissions])

    useEffect(() => {
        if (data?.permission && data?.file_id) {
            setCurrentUsers([]);
            getPermission({ url: `/files/${data?.file_id}/permissions`, params: { type: data?.permission } });
            setFilters(oldFilters => {
                return {
                    ...oldFilters,
                    file_id: data?.file_id,
                    file_permission: data?.permission,
                    with_permissions: '',
                    page: 1
                }
            })

            setData(oldData => ({ ...oldData, userIds: [] }))
        }
    }, [data?.permission, data?.file_id])

    const handleFilters = (e) => {
        setCurrentUsers([]);
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                [e.target.name]: e.target.value,
                page: 1
            }
        })
    }

    const handleUser = (userId) => {

        const haveValue = data?.userIds?.includes(userId);

        var newValues = [];

        if (haveValue) newValues = data?.userIds?.filter(value => value !== userId);

        if (!haveValue) newValues = [...data?.userIds, userId];

        setData((oldData) => {
            return {
                ...oldData,
                userIds: newValues
            }
        });
    }

    const handleAddPage = () => {
        setFilters((oldFilters) => {
            return {
                ...oldFilters,
                page: oldFilters?.page + 1
            }
        })
    }

    return (
        <div>
            <div className="col-md-12 mt-4 mb-5">
                <h4>
                    👨‍👦‍👦 Usuarios con acceso:
                </h4>
                <small>
                    Por favor seleccione los usuarios con acceso a este permiso:
                </small>
                <br />
                <br />
                <div className="row align-items-center">
                    <div className="col-md-9">
                        <div className="row align-items-center">
                            <div className="col-3 mb-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={filters?.search}
                                    onChange={handleFilters}
                                    name="search"
                                    placeholder="Buscar..."
                                />
                            </div>
                            <div className="col-md-3 mb-4">
                                <AsyncSelect
                                    isClearable
                                    onFocus={() => { getCostCenters() }}
                                    isLoading={costCentersLoading}
                                    defaultOptions={mapValues(costCenters)}
                                    name="costCenterId"
                                    loadOptions={(e) => handleLoadSelectOptions(e, getCostCenters, { orderBy: 'name ASC' })}
                                    placeholder='Escriba el nombre para buscar...'
                                    onChange={(e) => {
                                        handleFilters({ target: { value: e?.value, name: 'costCenterId' } })
                                    }}
                                />
                            </div>
                            <div className="col-3 mb-4">
                                <div className="d-flex align-items-center">
                                    <span className="d-none d-md-block" style={{ marginRight: 10 }}>Mostrar:</span>
                                    <select
                                        name="perPage"
                                        onChange={handleFilters}
                                        value={filters?.perPage}
                                        className="form-control"
                                        style={{ maxWidth: "30%" }}
                                    >
                                        <option value="12">12</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                    <span style={{ marginLeft: 5 }}>Registros por pagina</span>
                                </div>
                            </div>
                            <div className="col-3 mb-4">
                                <select
                                    onChange={handleFilters}
                                    name="with_permissions"
                                    value={filters?.with_permissions}
                                    className="form-control"
                                >
                                    <option value="">Todos</option>
                                    <option value="activos">Activos</option>
                                    <option value="inactivos">Inactivos</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 text-end mb-4">
                        <button className="btn btn-primary btn-xs" type="button" onClick={() => {
                            setData((oldData) => {
                                return {
                                    ...oldData,
                                    userIds: currentUsers?.map((user) => user?.id)
                                }
                            })
                        }}>
                            Seleccionar todos
                        </button>
                        <button className="btn btn-primary btn-xs" type="button" onClick={() => {
                            setData((oldData) => {
                                return {
                                    ...oldData,
                                    userIds: []
                                }
                            })
                        }}>
                            Remover todos
                        </button>
                    </div>
                </div>


                <ul className="custom-scrollbar scrollbar-primary mt-3 row align-items-center" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                    {
                        currentUsers?.length > 0 ?
                            currentUsers?.map((user, i) => {
                                return (
                                    <li
                                        key={i}
                                        onClick={(e) => handleUser(user?.id)}
                                        className="mb-3 px-3 col-md-6 col-lg-4"
                                    >
                                        <div
                                            className="d-flex custom-responsible-option p-2 border-primary"
                                            style={{
                                                borderLeft: '1px solid',
                                                alignItems: 'center',
                                                minHeight: 80,
                                                cursor: 'pointer',
                                                justifyContent: 'space-between',
                                                boxShadow: "0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"
                                            }}
                                        >
                                            <div className="d-flex align-items-center">
                                                <p className="text-primary" style={{ marginRight: 10, marginBottom: 0 }}>
                                                    {user?.id}.-
                                                </p>
                                                <Image
                                                    style={{ height: 40, width: 40, marginRight: 5 }}
                                                    src={user?.imagePath || profileImg}
                                                    roundedCircle
                                                />
                                                <div>
                                                    <h5 className="m-0">
                                                        {user?.name} - ({user?.email})
                                                    </h5>
                                                    <p className="m-0">
                                                        Rut: {user?.documentNumber}
                                                    </p>
                                                    <p className="m-0 text-primary">
                                                        {user?.costCenter?.name}
                                                    </p>
                                                </div>
                                            </div>
                                            {
                                                data?.userIds?.includes(user?.id) &&
                                                <div>
                                                    <AiFillCheckCircle className="text-primary" style={{ fontSize: 22 }} />
                                                </div>
                                            }
                                        </div>
                                    </li>
                                )
                            })
                            :
                            <li className="text-center">
                                No se encontrarón resultados
                            </li>
                    }
                    {
                        loadingUsers &&
                        <li>
                            <div className="spinner">
                                <div className="double-bounce1 bg-primary"></div>
                                <div className="double-bounce2 bg-primary"></div>
                            </div>
                        </li>
                    }
                    {
                        numberOfPages > filters?.page && !loadingUsers ?
                            <li className="text-center">
                                <button type="button" onClick={handleAddPage} className="btn btn-xs btn-primary" >
                                    Cargar mas
                                </button>
                            </li>
                            :
                            null
                    }
                </ul>
            </div>
        </div>
    )
}

export default UserPermissionsCrud