import ActionDropdown from "../../ActionDropdown";
import TableCheck from "../TableCheck";
import { cutString, dateFine } from "../../../util/Utilities";
import DateFormatter from "../../DateFormatter";
import clsx from "clsx";
import UserHavePermission from "../../../util/UserHavePermission";
import SystemInfo from "../../../util/SystemInfo";

const DocumentTypeColumns = [
    {
        Label: TableCheck,
        Component: TableCheck
    },
    {
        Label: () => 'id',
        accessor: 'id'
    },
    {
        Label: () => 'Nombre',
        Component: ({ value }) => `${value?.name || '--'}`
    },
    {
        Label: () => 'Prefijo',
        Component: ({ value }) => `${value?.prefix || '--'}`
    },
    {
        Label: () => 'Días de vigencia',
        Component: ({ value }) => `${value?.days || 'No vence nunca.'}`
    },
    {
        Label: () => 'Fecha de Creación',
        Component: ({ value }) => <DateFormatter value={dateFine(value?.createdAt)} dateFormat="dd/MM/yyyy hh:mm:ss" />
    },
    {
        Label: () => 'Acciones',
        Component: (props) => <ActionDropdown
            {...props}
            showDelete={UserHavePermission(`${SystemInfo?.systemCode}-delete-${props?.entity}`)}
        />
    }
];

export default DocumentTypeColumns;