import { createContext, useContext, useEffect, useState } from "react";
import useAxios from "../hooks/useAxios";

const FileFiltersContext = createContext({ filters: {}, setFilters: null, paths: [], setPaths: null });

export const FileFiltersProvider = ({ children }) => {

  const [filters, setFilters] = useState({
    search: '',
    parent_id: 1
  });

  const [currentFileDir, setCurrentFileDir] = useState({
    search: '',
    parent_id: 1
  });

  const [paths, setPaths] = useState([{ name: 'home', id: 1 }]);

  const [{ }, getFilePaths] = useAxios({}, { manual: true, useCache: false });

  useEffect(() => {
    getFilePaths({ url: `/files/${filters?.parent_id}` }).then((res) => {
      setPaths(res?.data?.data?.pathBuild);
      setCurrentFileDir(res?.data?.data);
    });
  }, [filters?.parent_id])

  return <FileFiltersContext.Provider value={{
    filters,
    setFilters,
    paths,
    setPaths,
    currentFileDir,
    setCurrentFileDir
  }}>
    {children}
  </FileFiltersContext.Provider>;
};

export const useFileFilters = () => useContext(FileFiltersContext);
