import { FaCheckCircle, FaCloudUploadAlt } from "react-icons/fa";
import useAxios from "../hooks/useAxios";
import { Modal, Spinner } from "react-bootstrap";
import { FaCloudDownloadAlt, FaHistory } from "react-icons/fa";

import { fileTypeToImage } from "../util/Utilities";
import { useEffect } from "react";


const FileVersionsModal = ({ file, show, setShow, onSave }) => {

    const [{ data: versionsData, loading: versionsLoading }, getVersions] = useAxios({ url: `files/${file?.id}/versions` }, { manual: true, useCache: false });

    useEffect(() => {
        if (show) getVersions();
    }, [show])

    return (
        <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FaHistory />
                    Historial de versiones
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="table-responsive">
                    <table className="table text-center">
                        <thead>
                            <tr>
                                <th>
                                    Nro de versión
                                </th>
                                <th>
                                    Extensión
                                </th>
                                <th>
                                    Fecha de baja
                                </th>
                                <th>
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                versionsLoading ?
                                    <tr>
                                        <td colSpan={4}>
                                            <div className="text-center">
                                                <Spinner variant="primary" style={{ width: 50, height: 50 }} animation="grow" />
                                            </div>
                                        </td>
                                    </tr>
                                    :
                                    !versionsData?.data?.length ?
                                        <tr>
                                            <td colSpan={4}>
                                                <div className="text-center">
                                                    <h4>
                                                        No se encontrarón versiones de este archivo.
                                                    </h4>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        versionsData?.data?.map((version, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        {i + 1}
                                                    </td>
                                                    <td style={{ fontSize: 14, textTransform: 'capitalize' }}>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <img src={fileTypeToImage(version?.extension)} alt={version?.name} style={{ width: 25, height: 20 }} />
                                                            <p className="m-0 ms-2 text-center">
                                                                {version?.extension}
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {version?.createdAt || '--'}
                                                    </td>
                                                    <td>
                                                        <div class="btn-group" role="group" aria-label="Basic outlined example">
                                                            <button type="button" class="btn btn-outline-primary">
                                                                <FaCloudDownloadAlt />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                            }
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FileVersionsModal;