import { useEffect, useState } from "react";
import { Dropdown, Modal, Spinner } from "react-bootstrap"
import { FaFileUpload, FaCloudUploadAlt } from "react-icons/fa";
import useAxios from "../hooks/useAxios";
import { getExtension } from "../util/Utilities";
import useCostCenters from "../hooks/useCostCenters";
import mapValues from "../util/mapValues";
import handleLoadSelectOptions from "../util/handleLoadSelectOptions";
import AsyncSelect from 'react-select/async';
import { format } from "date-fns";


const CreateFileComponent = ({ parentId, onCreate }) => {

    const [show, setShow] = useState(false);

    const [count, setCount] = useState(0);

    const [documentTypeId, setDocumentTypeId] = useState('');

    const [selectedDocumentType, setSelectedDocumentType] = useState(null);

    const [costCenter, setCostCenter] = useState(null);

    const [toCount, setToCount] = useState(0);

    const [filesWithErrors, setFileWithErrors] = useState([]);

    const [{ data: storeData, loading: storeLoading }, storeFile] = useAxios({ url: `/files`, method: 'POST' }, { manual: true, useCache: false });

    const [{ data: documentTypesData, loading: documentTypesLoading }, getDocumentTypes] = useAxios({ url: `/document-types`, method: 'GET', params: { perPage: 200 } }, { manual: true, useCache: false });

    const [{ costCenters, loading: costCentersLoading }, getCostCenters] = useCostCenters({ params: { perPage: 50, orderBy: 'name ASC' } }, { useCache: false });

    useEffect(() => {
        if (show) getDocumentTypes({ params: { perPage: 200 } });
    }, [show])

    const handleFiles = async (e) => {
        setToCount(e.target.files.length);
        setFileWithErrors([]);
        var uploadedFiles = 0;
        for (let index = 0; index < e.target.files.length; index++) {
            const newFile = e.target.files[index];
            try {

                var data = new FormData();
                data?.append('name', newFile?.name);
                data?.append('file', newFile, newFile?.name);
                data?.append('extension', getExtension(newFile?.name));
                data?.append('parent_id', parentId);

                if (documentTypeId) data?.append('document_type_id', documentTypeId);

                if (costCenter) data?.append('cost_center_id', costCenter?.value);

                const fileResponse = await storeFile({ data });

                if (fileResponse) {
                    uploadedFiles = uploadedFiles + 1;
                    setCount(oldCount => oldCount + 1);
                }
            } catch (error) {
                setFileWithErrors(oldValue => {
                    return [...oldValue, { file_name: newFile?.name, error_message: error?.response?.data?.message }]
                });
            }
        }

        onCreate?.();

        if (uploadedFiles === e.target.files.length) setShow(false);
    }

    const handleDocumentType = (e) => {
        setDocumentTypeId(e.target.value);
        console.log(e.target.value);
        if (e.target.value) {
            const currentDocumentTypes = documentTypesData?.data;
            const selectedDocumentTypes = currentDocumentTypes.filter((documentType) => documentType?.id === Number(e.target.value));
            if (selectedDocumentTypes.length) setSelectedDocumentType(selectedDocumentTypes[0]);
        } else {
            console.log('hello');
            setSelectedDocumentType(null);
        }
    }

    return (
        <>
            <Dropdown.Item onClick={() => setShow(true)}>
                <FaFileUpload />
                Nuevo Documento
            </Dropdown.Item>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo documento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        storeLoading ?
                            <div className="text-center my-5">
                                <Spinner animation="grow" variant="primary" />
                                <p>
                                    Cargando {count} de {toCount} archivos.
                                </p>
                            </div>
                            :
                            <>
                                <div className="form-floating mb-3">
                                    <select className="form-select" id="document_type_id" name="document_type_id" value={documentTypeId} onChange={handleDocumentType}>
                                        <option selected value="">Seleccione una opción</option>
                                        {
                                            documentTypesData?.data?.map((documentType, i) => {
                                                return (
                                                    <option value={documentType?.id} key={i}>
                                                        <b>
                                                            {documentType?.prefix} -
                                                        </b>
                                                        <span> {documentType?.name}</span>
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <label for="document_type_id">Tipo de documento</label>
                                </div>

                                {
                                    selectedDocumentType &&
                                    <div className="animate__animated animate__fadeInLeft mb-3">
                                        {
                                            selectedDocumentType?.days ?
                                                <>
                                                    <p className="mb-0">
                                                        Los documentos cargados tendrán una vigencia de: <b>{selectedDocumentType?.days} días.</b>
                                                    </p>
                                                    <p className="mb-0">
                                                        La fecha de expiración de los documentos será el: <b>{format(new Date().setDate(new Date().getDate() + selectedDocumentType?.days), 'dd-MM-yyyy')}</b>
                                                    </p>
                                                </>
                                                :
                                                <div>
                                                    <b>Los documentos no tienen fecha de expiración.</b>
                                                </div>
                                        }
                                    </div>
                                }

                                <div className="mb-3">
                                    <label>
                                        Centro de costo
                                    </label>
                                    <AsyncSelect
                                        isClearable
                                        onFocus={() => { getCostCenters() }}
                                        isLoading={costCentersLoading}
                                        defaultOptions={mapValues(costCenters)}
                                        value={costCenter}
                                        name="costCenterId"
                                        loadOptions={(e) => handleLoadSelectOptions(e, getCostCenters, { orderBy: 'name ASC' })}
                                        placeholder='Escriba el nombre para buscar...'
                                        onChange={(e) => {
                                            setCostCenter(e);
                                        }}
                                    />
                                </div>

                                <label htmlFor="file-input" className="d-flex border w-100" style={{ height: 200, cursor: 'pointer' }}>
                                    <p className="m-auto text-center">
                                        <FaCloudUploadAlt className="d-block m-auto" style={{ fontSize: 80 }} />
                                        Haz click aqui para subir los archivos.
                                    </p>
                                    <input type="file" id="file-input" multiple className="d-none" onChange={handleFiles} />
                                </label>
                            </>
                    }
                    {
                        !storeLoading ?
                            filesWithErrors?.map((fileError) => {
                                return (
                                    <div className="alert alert-danger">
                                        <b>Error:</b> El archivo <b>{fileError?.file_name}</b> no se ha podido subir.
                                        <br />
                                        <b>Motivo:</b> {fileError?.error_message}
                                    </div>
                                )
                            })
                            :
                            null
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CreateFileComponent;