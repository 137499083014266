import { Link } from "react-router-dom";
import UserHavePermission from "../util/UserHavePermission";
import { mainPermissions } from "../util/MenuLinks";

const DocumentTypesActionsComponent = () => {
    return (
        <div className="text-end">
            {
                UserHavePermission(mainPermissions?.documentTypes?.[1]) &&
                <Link to={`/tipos-de-documentos/crear`} className="btn btn-primary">
                    Crear
                </Link>
            }
        </div>
    )
}

export default DocumentTypesActionsComponent;