import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useAxios from "../hooks/useAxios";
import { filePermissionTypeToName } from "../util/Utilities";
import clsx from "clsx";
import UserPermissionsCrud from "./UserPermissionsCrud";
import { AiFillCheckCircle } from "react-icons/ai";

const ManagementFilePermissionsModal = ({ show, file, setShow }) => {

    const [{ data: permissionTypes, loading }, getFilePermissionTypes] = useAxios({ url: `files/${file?.id}/file-permission-types` }, { useCache: false, manual: true });

    const [{ data: storeData, loading: loadingStore }, storeFilePermissions] = useAxios({ url: `/file-permissions`, method: 'POST' }, { useCache: false, manual: true });

    const [showSuccess, setShowSuccess] = useState(false);

    const [data, setData] = useState({
        permission: '',
        userIds: [],
        file_id: ''
    });

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false);
            }, 3000);
        }
    }, [showSuccess])

    useEffect(() => {
        if (permissionTypes) setData(oldData => ({ ...oldData, permission: permissionTypes?.[0] }));
    }, [permissionTypes])

    useEffect(() => {
        if (show) getFilePermissionTypes();
    }, [show])

    useEffect(() => {
        if (file) setData(oldData => ({ ...oldData, file_id: file?.id, userIds: [] }))
    }, [file])

    const handleUpdate = () => {
        storeFilePermissions?.({ data }).then(res => {
            setShowSuccess(true);
        })
    }

    return (
        <Modal size="xl" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Gestionar permisos del documento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-lg-flex align-items-center justify-content-between">
                    {
                        permissionTypes?.map((value, i) => {
                            return (
                                <div className={clsx(["w-100 text-center btn"], {
                                    'btn-primary': data?.permission === value
                                })} style={{ fontSize: 14 }} key={i} onClick={() => setData(oldData => ({ ...oldData, permission: value }))}>
                                    <span className="me-md-3" style={{ fontSize: 20 }}>{filePermissionTypeToName?.[value]?.Icon}</span>
                                    <span style={{ textTransform: 'uppercase' }}>
                                        {filePermissionTypeToName?.[value]?.name}
                                    </span>
                                </div>
                            )
                        })
                    }
                </div>
                <UserPermissionsCrud data={data} setData={setData} />
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn text-dark me-1 fw-bold" onClick={() => setShow(false)}>
                    Cerrar
                </button>
                <button disabled={loadingStore} type="button" className={
                    clsx(["btn me-1 fw-bold"], {
                        "text-primary": !showSuccess,
                        "text-success": showSuccess,
                    })
                } onClick={handleUpdate}>
                    {

                        loadingStore ?
                            'Cargando...'
                            :
                            showSuccess ?
                                <span>
                                    Los cambios se han guardado exitosamente
                                    <AiFillCheckCircle />
                                </span>
                                :
                                'Guardar cambios'
                    }
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ManagementFilePermissionsModal;